@import '~leaflet/dist/leaflet.css';

/* Leaflet container styles */
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.custom-icon {
  background: transparent;
  border: none;
}

.custom-icon div {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  color: white;
  font-size: 12px;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
  line-height: 30px;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
}

#root {
  height: 100%;
  overflow-x: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

.app-header, .app-footer {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  position: sticky;
  width: 100%;
  z-index: 1000;
}

.app-header {
  background: linear-gradient(to bottom, #263455, #bf2c2b);
  color: white;
  justify-content: space-between;
  top: 0;
}

.app-footer {
  background: linear-gradient(to top, #263455, #bf2c2b);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  position: sticky;
  width: 100%;
  z-index: 1000;
  bottom: 0;
}

.app-logo {
  height: 30px;
}

.app-title {
  flex-grow: 1;
  text-align: center;
  font-size: 1.2rem;
  margin: 0;
}

.app-main {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.footer-buttons-container {
  display: flex;
  justify-content: center;
  gap: 40px; /* This adds space between the buttons */
}

.footer-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem 1rem 1rem;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.footer-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.footer-button:focus,
.footer-button:active {
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
  box-shadow: none;
}

.footer-button:active {
  transform: scale(0.95);
}

.circle-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  pointer-events: none;
}

.leaflet-popup-content-wrapper {
  padding: 0;
  overflow: hidden;
}

.popup-content {
  padding: 10px;
  position: relative;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.program-number-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 2px;
  box-sizing: border-box;
}

.campaign-name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  flex-grow: 1;
  padding-right: 35px;
}

.location-name {
  font-size: 16px;
  margin: 0 0 10px 0;
  color: #555;
}

.description {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
  text-align: left;
}

/* Styles for Event List */
.event-list {
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
  background: #f9f9f9;
}

.event-item {
  margin-bottom: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.event-item:hover {
  background-color: #f0f0f0;
}

.event-item .popup-content {
  padding: 10px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-item .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.event-item .campaign-name {
  font-size: 18px;
  font-weight: bold;
}

.event-item .location-name {
  font-size: 16px;
  margin: 0 0 10px 0;
  color: #555;
}

.event-item .description {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

/* Styles for Legend */
.legend {
  position: absolute;
  bottom: 20px;
  left: 10px;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.legend h4 {
  margin: 0 0 10px 0;
}

.legend ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.legend li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
}

/* Styles for Splash Screen */
.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #bf2c2b, #263455);
  z-index: 1000;
}

.splash-logo {
  height: 60px;
  margin-bottom: 20px;
}

.splash-text {
  color: white;
  font-size: 1.2rem;
}

.custom-icon svg {
  width: 100%;
  height: 100%;
}

/* New styles for view transitions */
.view-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.map-view,
.list-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.view-container.map .map-view {
  transform: translateX(0);
  z-index: 1;
}

.view-container.map .list-view {
  transform: translateX(100%);
  z-index: 0;
}

.view-container.list .map-view {
  transform: translateX(-100%);
  z-index: 0;
}

.view-container.list .list-view {
  transform: translateX(0);
  z-index: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.highlighted-marker {
  z-index: 1000 !important;
}

.highlighted-marker .custom-icon {
  animation: pulse 1s ease-in-out 3;
}

.highlighted-marker::after {
  content: '';
  position: absolute;
    /* top: 10%;
  left: 1%;
  transform: translate(-50%, -50%); */
  width: 40px;
  height: 40px;
  background-color: rgba(255, 0, 0, 0.8);
  border-radius: 50%;
  z-index: -1;
  animation: pulse 1s ease-in-out 3;
}

.custom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.program-number-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  padding: 2px;
  box-sizing: border-box;
}